import React, { useEffect, useState, useRef } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Modal, Container, Form, SpaceBetween, Button, Header, ColumnLayout, FormField, ButtonDropdown, Alert, Textarea, Slider, Spinner } from '@cloudscape-design/components';
import { ItemContext } from '../context/itemContext';
import { SelectedItemContextType } from '../@types/item';
import { UserInfoContextType, UserInfo } from '../@types/user';
import { UserContext } from '../context/userContext';

export default function SolutionsFit(dataTable: any) {
    const apiUrl = 'https://h85kpb567c.execute-api.eu-west-3.amazonaws.com/prod';
    const path = '/updateItem';



    const { item, updateItem } = React.useContext(ItemContext) as SelectedItemContextType;
        const { user, updateUser } = React.useContext(UserContext) as UserInfoContextType;

    //Alert message
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState<any>("success");
    const alertMessageRef = useRef<HTMLInputElement>(null);
    const [showSubmitButton, setShowSubmitButton] = useState(true);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [showCloseButton, setShowCloseButton] = useState(false);

    //Business Landscape
    const [tap, setTAP] = useState('No');
    const [operationalAreas, setOperationalAreas] = useState('No');
    const [multipleCountries, setMultipleCountries] = useState('No');
    const [redFlagCountries, setRedFlagCountries] = useState('No');
    const [matchingLanguageSet, setMatchingLanguageSet] = useState('No');
    const [nativeChannelsSupport, setNativeChannelsSupport] = useState('No');
    const [drOnly, setDROnly] = useState('No');
    const [crmSolution, setCRMSolution] = useState('');

    //Existing Tech Landscape

    const [existingSolution, setExistingSolution] = useState('');
    const [existingCloudOrPremise, setExistingCloudOrPromise] = useState('');
    const [percentageOutboundDialer, setPercentageOutboundDialer] = useState(20);
    const [e2eSolutionAware, sete2eSolutionAware] = useState('No');
    const [deliveryModel, setDeliveryModel] = useState('No');
    const [tenderOtherVendor, setTenderOtherVendor] = useState('No');

    //Solutions Landscape
    const [cloudCustomer, setCloudCustomer] = useState('No');
    const [byoc, setBYOC] = useState('No');
    const [pbxRequirements, setPBXRequirements] = useState('No');
    const [backOfficeIntegration, setBackOfficeIntegration] = useState('No');
    const [followTheSun, setFollowTheSun] = useState('No');
    const [requirementNature, setRequirementNature] = useState('');

    //Next Steps
    const [nextSteps, setNextSteps] = useState('');
    const [setExpectations, setSetExpectations] = useState('');
    const [solutionConsultant, setSolutionConsultant] = useState('');

    useEffect(() => {
        if(alertVisible) {
            alertMessageRef.current?.focus();
        }
    }, [alertVisible])

    const content = (
        <>
            <Modal
                onDismiss={() => {
                    updateItem({ id: item.id, owner: item.owner, visible: false, soutionsFitVisible: false, businessFitVisible: false })
                }
                }
                visible={item.soutionsFitVisible}
                size='max'
                header={item.id}
            >
                {alertVisible == true && (
                    <Alert
                        type={alertType}
                        ref={alertMessageRef}
                        statusIconAriaLabel={alertType}
                        dismissible
                        onDismiss={() => {
                            if (alertType === 'success') {
                                updateItem({ id: item.id, owner: item.owner, visible: false, soutionsFitVisible: false, businessFitVisible: false });
                                setAlertVisible(false);
                            }
                            else {
                                setAlertVisible(false);
                            }

                        }

                        }
                    >{alertMessage} </Alert>
                )}
                <form onSubmit={e => e.preventDefault()}>
                    <Form
                        actions={
                            <SpaceBetween direction="horizontal" size="s">
                                <Button formAction="none" variant="link" onClick={() => {
                                    updateItem({ id: item.id, owner: item.owner, visible: false, soutionsFitVisible: false, businessFitVisible: false })
                                }}>
                                    Cancel
                                </Button>
                                {showSubmitButton === true && (
                                    <Button variant="primary" disabled={submitButtonDisabled} onClick={async (e) => {
                                        setSubmitButtonDisabled(true);
                                    if(crmSolution === "") setCRMSolution("Unknown");
                                    if(existingSolution === "") setExistingSolution("Unknown");
                                    if(existingCloudOrPremise === "") setExistingCloudOrPromise("Unknown");
                                    if(requirementNature === "") setRequirementNature("Unknown");
                                    if(nextSteps === "") setNextSteps("Unknown");
                                    if(setExpectations === "") setSetExpectations("Unknown");
                                    if(solutionConsultant === "") setSolutionConsultant("Unknown");
                                    
                                    const object = {
                                        id: item.id,
                                        postQualificationSolution: "Completed",
                                        owner: item.owner,
                                        tap: tap,
                                        operationalAreas: operationalAreas,
                                        multipleCountries: multipleCountries,
                                        redFlagCountries: redFlagCountries,
                                        matchingLanguageSet: matchingLanguageSet,
                                        nativeChannelsSupport: nativeChannelsSupport,
                                        drOnly: drOnly,
                                        crmSolution: crmSolution,
                                        existingSolution: existingSolution,
                                        existingCloudOrPremise: existingCloudOrPremise,
                                        percentageOutboundDialer: percentageOutboundDialer,
                                        e2eSolutionAware: e2eSolutionAware,
                                        deliveryModel: deliveryModel,
                                        tenderOtherVendor: tenderOtherVendor,
                                        cloudCustomer: cloudCustomer,
                                        byoc: byoc,
                                        pbxRequirements: pbxRequirements,
                                        backOfficeIntegration: backOfficeIntegration,
                                        followTheSun: followTheSun,
                                        requirementNature: requirementNature,
                                        nextSteps: nextSteps,
                                        setExpectations: setExpectations,
                                        solutionConsultant: solutionConsultant,
                                        updatedBy: user.username,
                                    }
                                    const token = (await fetchAuthSession()).tokens?.idToken?.toString();
                                    try {
                                        const response = await fetch(apiUrl + path, {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'Authorization': `Bearer ${token}`
                                            },
                                            body: JSON.stringify(object)
                                        });
                                        const body = await response.json();
                                        console.log(JSON.stringify(body));
                                        if (response.status == 200) {
                                            setAlertMessage("Solutions Fit successfully saved");
                                            setAlertType("success");
                                            setAlertVisible(true);
                                            setSubmitButtonDisabled(false);
                                            setShowSubmitButton(false);
                                            setShowCloseButton(true);

                                            setTimeout(() => {
                                                updateItem({ id: item.id, owner: item.owner, visible: false, soutionsFitVisible: false, businessFitVisible: false });
                                                window.location.reload();
                                            }, 5000);

                                        } else {
                                            setAlertMessage(`${body}`);
                                            setAlertType("error");
                                            setAlertVisible(true);

                                            setSubmitButtonDisabled(false);
                                        }
                                    } catch (error) {
                                        console.log(`Error on fetch ${JSON.stringify(error)}`);
                                        setAlertMessage(`Error saving Solutions Fit  ${error}`);
                                        setAlertType("error");
                                        setAlertVisible(true);
                                        setSubmitButtonDisabled(false)
                                    }




                                }}>{submitButtonDisabled === true && (<Spinner />)}Submit</Button>
                                )}

                                {showCloseButton === true && (
                                    <Button formAction='none' variant='primary' onClick={() => {
                                        updateItem({ id: item.id, owner: item.owner, visible: false, soutionsFitVisible: false, businessFitVisible: false })
                                    }}>Close</Button>
                                )}
                                
                            </SpaceBetween>
                        }
                    >
                        <SpaceBetween size="m">
                            <Container
                                header={
                                    <Header variant="h2">Business Landscape</Header>
                                }>
                                <ColumnLayout columns={4} variant="text-grid">
                                    <FormField label="TAP" description="Do we have a Technical Account Plan (TAP)?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setTAP(event.detail.id);
                                            }}
                                        >{tap} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="Operational Areas" description="Does the customer have other operational areas, such as branches or homeworkers?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setOperationalAreas(event.detail.id);
                                            }}
                                        >{operationalAreas} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="Multiple Countries" description="Does the customer operate in multiple regions/countries?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setMultipleCountries(event.detail.id);
                                            }}
                                        >{multipleCountries} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="Red-Flag Countries" description="Are there any red flag countries (China, Russia, India etc.)?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setRedFlagCountries(event.detail.id);
                                            }}
                                        >{redFlagCountries} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="Language Support" description="Do we have adequate language support for the customers regions/countries ?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setMatchingLanguageSet(event.detail.id);
                                            }}
                                        >{matchingLanguageSet} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="Native Channels" description="Do we support all channels (being requested) natively?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setNativeChannelsSupport(event.detail.id);
                                            }}
                                        >{nativeChannelsSupport} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="DR Only" description="Is this a Disaster-Recovery(DR) solution only?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setDROnly(event.detail.id);
                                            }}
                                        >{drOnly} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="CRM Solution" description="Do we know the customers CRM solution(s), if so what are they?">
                                        <Textarea
                                            onChange={({ detail }) => setCRMSolution(detail.value)}
                                            value={crmSolution}
                                            placeholder="Enter CRM Solution if available."
                                        />
                                    </FormField>

                                </ColumnLayout>

                            </Container>
                            <Container
                                header={
                                    <Header variant="h2">Existing Tech. landscape</Header>
                                }>
                                <ColumnLayout columns={3} variant="text-grid">
                                    <FormField label="Existing Solution" description="What solution technologies exist today?">
                                        <Textarea
                                            onChange={({ detail }) => setExistingSolution(detail.value)}
                                            value={existingSolution}
                                            placeholder="Enter existing Solution if available."
                                        />
                                    </FormField>
                                    <FormField label="Cloud or On-Premise" description="Are these Solutions cloud or on-prem solution(s)?">
                                        <Textarea
                                            onChange={({ detail }) => setExistingCloudOrPromise(detail.value)}
                                            value={existingCloudOrPremise}
                                            placeholder="Please describe."
                                        />
                                    </FormField>
                                    <FormField label="Outbound (Dialer heavy)" description="What percentage of calls are outbound (dialler heavy)?">
                                        <Slider
                                            onChange={({ detail }) => setPercentageOutboundDialer(detail.value)}
                                            value={percentageOutboundDialer}
                                            valueFormatter={value => value + "%"}
                                            max={100}
                                            min={0}
                                            referenceValues={[25, 50, 75]}
                                        />
                                    </FormField>
                                    <FormField label="Solution Components" description="Do we understand the end to end solution components (architectures) i.e. WFM, Reporting, CRM etc.">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                sete2eSolutionAware(event.detail.id);
                                            }}
                                        >{e2eSolutionAware} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="Delivery Model" description="Do we understand the delivery model that serves the customer best (Partner or Proserve)?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setDeliveryModel(event.detail.id);
                                            }}
                                        >{deliveryModel} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="Other Vendor fit" description="Does this tender read as though it has been written for another vendor?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setTenderOtherVendor(event.detail.id);
                                            }}
                                        >{tenderOtherVendor} </ButtonDropdown>
                                    </FormField>

                                </ColumnLayout>

                            </Container>
                            <Container
                                header={
                                    <Header variant="h2">Solutions Landscape</Header>
                                }>
                                <ColumnLayout columns={3} variant="text-grid">
                                    <FormField label="Cloud Customer" description="Is the customer bought into Cloud?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setCloudCustomer(event.detail.id);
                                            }}
                                        >{cloudCustomer} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="BYOC" description="Is there an expectation for bring-your-own-carrier(BYOC)?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setBYOC(event.detail.id);
                                            }}
                                        >{byoc} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="PBX requirements" description="Are there Unified Communications (PBX) requirements as a part of the RFP?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setPBXRequirements(event.detail.id);
                                            }}
                                        >{pbxRequirements} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="Backoffice requirements" description="Does the customer require back office integration (i.e. MS Team)?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setBackOfficeIntegration(event.detail.id);
                                            }}
                                        >{backOfficeIntegration} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="Global Solution" description="Is this a Global solution (do they require follow the sun requirements)?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setFollowTheSun(event.detail.id);
                                            }}
                                        >{followTheSun} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="Customer requirements" description="Please describe the nature of the customers requirement .">
                                    <Textarea
                                            onChange={({ detail }) => setRequirementNature(detail.value)}
                                            value={requirementNature}
                                            placeholder="Describe the customer requirements."
                                        />
                                    </FormField>

                                </ColumnLayout>

                            </Container>


                            <Container
                                header={
                                    <Header variant="h2">Next Steps</Header>
                                }>
                                <ColumnLayout columns={1} variant="text-grid">
                                <FormField label="Next steps" description="What are the next steps?">
                                    <Textarea
                                            onChange={({ detail }) => setNextSteps(detail.value)}
                                            value={nextSteps}
                                            placeholder="Describe the next steps."
                                        />
                                    </FormField>
                                    <FormField label="Customer expectation" description="What expectations have been set with the customer?">
                                    <Textarea
                                            onChange={({ detail }) => setSetExpectations(detail.value)}
                                            value={setExpectations}
                                            placeholder="Describe the customer expectations."
                                        />
                                    </FormField>
                                    <FormField label="Solutions Consultant" description="Do you have a solutions consultant aligned to this opporunity already, if so whom?">
                                    <Textarea
                                            onChange={({ detail }) => setSolutionConsultant(detail.value)}
                                            value={solutionConsultant}
                                            placeholder="Describe the customer requirements."
                                        />
                                    </FormField>
                                </ColumnLayout>
                            </Container>
                        </SpaceBetween>
                    </Form>
                </form>

            </Modal>
        </>
    )

    return content

}