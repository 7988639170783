import React, { useEffect, useState, useRef } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Modal, Container, Form, SpaceBetween, Button, Header, ColumnLayout, FormField, ButtonDropdown, Alert, Spinner } from '@cloudscape-design/components';
import { ItemContext } from '../context/itemContext';
import { SelectedItemContextType } from '../@types/item';
import { UserInfoContextType, UserInfo } from '../@types/user';
import { UserContext } from '../context/userContext';

export default function BusinessFit(dataTable: any) {
    const apiUrl = 'https://h85kpb567c.execute-api.eu-west-3.amazonaws.com/prod';
    const path = '/updateItem';



    const { item, updateItem } = React.useContext(ItemContext) as SelectedItemContextType;
    const { user, updateUser } = React.useContext(UserContext) as UserInfoContextType;

    //Alert message
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState<any>("success");
    const alertMessageRef = useRef<HTMLInputElement>(null);
    const [showSubmitButton, setShowSubmitButton] = useState(true);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const [showCloseButton, setShowCloseButton] = useState(false);

    //Business Fit Items

    //Budget
    const [pricingApproach, setPricingAproach] = useState('No');
    const [increaseSize, setIncreaseSize] = useState('No');
    const [owp, setOwp] = useState('No');
    const [currentSpent, setCurrentSpent] = useState('No');

    //Authority
    const [buyerPersoa, setBuyerPersona] = useState('No');
    const [procurementAccess, setProcurementAccess] = useState('No');
    const [decisionProcess, setDecisionProcess] = useState('No');


    //Need
    const [objective, setObjective] = useState('Tactical');
    const [specificOutcome, setSpecificOutcome] = useState('No');
    const [obtainAWSCapability, setObtainAWSCapability] = useState('No');
    const [partnerCost, setPartnerCost] = useState('No');

    //Timing
    const [timescales, setTimescales] = useState('No');
    const [compellingEvent, setCompellingEvent] = useState('No');
    const [capabilityDeadline, setCapabilityDeadline] = useState('No');

    useEffect(() => {
        if(alertVisible) {
            alertMessageRef.current?.focus();
        }
    }, [alertVisible])

    const content = (
        <>
            <Modal
                onDismiss={() => {
                    updateItem({ id: item.id, owner: item.owner, visible: false, soutionsFitVisible: false, businessFitVisible: false, editBusinessFitVisible: false })
                }
                }
                visible={item.businessFitVisible}
                size='max'
                header={item.id}
            >
                {alertVisible === true && (
                    <Alert 
                    ref={alertMessageRef}
                        type={alertType}
                        statusIconAriaLabel={alertType}
                        dismissible
                        onDismiss={() => {
                            if (alertType === 'success') {
                                updateItem({ id: item.id, owner: item.owner, visible: false, soutionsFitVisible: false, businessFitVisible: false, editBusinessFitVisible: false });
                                setAlertVisible(false);
                            }
                            else {
                                setAlertVisible(false);
                            }

                        }

                        }
                    >{alertMessage} </Alert>
                )}
                <form onSubmit={e => e.preventDefault()}>
                    <Form
                        actions={
                            <SpaceBetween direction="horizontal" size="s">
                                <Button formAction="none" variant="link" onClick={() => {
                                    updateItem({ id: item.id, owner: item.owner, visible: false, soutionsFitVisible: false, businessFitVisible: false, editBusinessFitVisible: false })
                                }}>
                                    Cancel
                                </Button>
                                {showSubmitButton === true && (
                                    <Button variant="primary" onClick={async (e) => {
                                        setSubmitButtonDisabled(true);
                                        const object = {
                                            id: item.id,
                                            pricingApproach: pricingApproach,
                                            increaseSize: increaseSize,
                                            owp: owp,
                                            currentSpent: currentSpent,
                                            buyerPersoa: buyerPersoa,
                                            procurementAccess: procurementAccess,
                                            decisionProcess: decisionProcess,
                                            objective: objective,
                                            specificOutcome: specificOutcome,
                                            obtainAWSCapability: obtainAWSCapability,
                                            partnerCost: partnerCost,
                                            timescales: timescales,
                                            compellingEvent: compellingEvent,
                                            capabilityDeadline: capabilityDeadline,
                                            postQualificationBusiness: "Completed",
                                            owner: item.owner,
                                            updatedBy: user.username,
                                        }
                                        const token = (await fetchAuthSession()).tokens?.idToken?.toString();
                                        try {
                                            const response = await fetch(apiUrl + path, {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    'Authorization': `Bearer ${token}`
                                                },
                                                body: JSON.stringify(object)
                                            });
                                            const body = await response.json();
                                            console.log(JSON.stringify(body));
                                            if (response.status === 200) {
                                                setAlertMessage("Business Fit successfully saved");
                                                setAlertType("success");
                                                setAlertVisible(true);
                                                setSubmitButtonDisabled(false);
                                                setShowSubmitButton(false);
                                                setShowCloseButton(true);

                                                setTimeout(() => {
                                                    updateItem({ id: item.id, owner: item.owner, visible: false, soutionsFitVisible: false, businessFitVisible: false, editBusinessFitVisible: false });
                                                    window.location.reload();
                                                }, 5000);

                                            } else {
                                                setAlertMessage(`${body}`);
                                                setAlertType("error");
                                                setAlertVisible(true);
                                                setSubmitButtonDisabled(false);
                                            }
                                        } catch (error) {
                                            console.log(`Error on fetch ${JSON.stringify(error)}`);
                                            setAlertMessage(`Error saving Business Fit  ${error}`);
                                            setAlertType("error");
                                            setAlertVisible(true);
                                            setSubmitButtonDisabled(false);
                                        }




                                    }}>{submitButtonDisabled === true && (<Spinner />)}Submit</Button>
                                )}

                                {showCloseButton === true && (
                                    <Button formAction='none' variant='primary' onClick={() => {
                                        updateItem({ id: item.id, owner: item.owner, visible: false, soutionsFitVisible: false, businessFitVisible: false, editBusinessFitVisible: false })
                                    }}>Close</Button>
                                )}

                            </SpaceBetween>
                        }
                    >
                        <SpaceBetween size="m">
                            <Container
                                header={
                                    <Header variant="h2">Budget</Header>
                                }>
                                <ColumnLayout columns={4} variant="text-grid">
                                    <FormField label="Consumption Pricing" description="Is the customer budgeted for and bought into our consumption pricing approach?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setPricingAproach(event.detail.id);
                                            }}
                                        >{pricingApproach} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="Increase Operation" description="Is there an initiative to increase the size of the operation?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setIncreaseSize(event.detail.id);
                                            }}
                                        >{increaseSize} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="OWP" description="Do we have an opportunity win plan (OWP)?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setOwp(event.detail.id);
                                            }}
                                        >{owp} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="Defined Budget" description="Have they told us that they have defined budget/do we know their current spend?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setCurrentSpent(event.detail.id);
                                            }}
                                        >{currentSpent} </ButtonDropdown>
                                    </FormField>

                                </ColumnLayout>

                            </Container>
                            <Container
                                header={
                                    <Header variant="h2">Need</Header>
                                }>
                                <ColumnLayout columns={4} variant="text-grid">
                                    <FormField label="Objective" description="What is the objective? Tactical optimisation vs strategic transformation.">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Tactical", id: "Tactical", disabled: false },
                                                { text: "Strategic", id: "Strategic", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setObjective(event.detail.id);
                                            }}
                                        >{objective} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="Defined Outcome" description="Has the customer expressed specific outcomes for the delivery of new cababilities?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setSpecificOutcome(event.detail.id);
                                            }}
                                        >{specificOutcome} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="AWS Capabilities" description="Has the customer expressed a desire to obtain a capability they don't have, which AWS could provide?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setObtainAWSCapability(event.detail.id);
                                            }}
                                        >{obtainAWSCapability} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="Partner costs" description="Partner costsHas the customer expressed a need to receive implementation/consulting partner costs?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setPartnerCost(event.detail.id);
                                            }}
                                        >{partnerCost} </ButtonDropdown>
                                    </FormField>

                                </ColumnLayout>

                            </Container>
                            <Container
                                header={
                                    <Header variant="h2">Authority</Header>
                                }>
                                <ColumnLayout columns={3} variant="text-grid">
                                    <FormField label="Buying Persona" description="Are we talking to a buying or approving persona?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setBuyerPersona(event.detail.id);
                                            }}
                                        >{buyerPersoa} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="Economic Buyer" description="Do we have a direct relationship with the economic buyer (i.e. procurement)?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setProcurementAccess(event.detail.id);
                                            }}
                                        >{procurementAccess} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="Decision Process" description="Do we know what the buying/decision making process is (scoring, price, weighting etc)?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setDecisionProcess(event.detail.id);
                                            }}
                                        >{decisionProcess} </ButtonDropdown>
                                    </FormField>

                                </ColumnLayout>

                            </Container>


                            <Container
                                header={
                                    <Header variant="h2">Timing</Header>
                                }>
                                <ColumnLayout columns={3} variant="text-grid">
                                    <FormField label="Timescales" description="Do we know the expected implementation timescales?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setTimescales(event.detail.id);
                                            }}
                                        >{timescales} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="Compelling Event" description="Has their current solution met its vendor's end of life or is there a major compelling event?">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setCompellingEvent(event.detail.id);
                                            }}
                                        >{compellingEvent} </ButtonDropdown>
                                    </FormField>
                                    <FormField label="Capability deadline" description="Has an executive imposed/declared a deadline for having new capabilities? (E.g., New regulation, product launch)">
                                        <ButtonDropdown
                                            items={[
                                                { text: "Yes", id: "Yes", disabled: false },
                                                { text: "No", id: "No", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setCapabilityDeadline(event.detail.id);
                                            }}
                                        >{capabilityDeadline} </ButtonDropdown>
                                    </FormField>

                                </ColumnLayout>
                            </Container>
                        </SpaceBetween>
                    </Form>
                </form>

            </Modal>
        </>
    )

    return content

}