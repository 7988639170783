import React, { useEffect, useState } from 'react';
import { Modal, Box, SpaceBetween, Toggle, Container, Header, Popover, Link, Form, ColumnLayout, StatusIndicator, Badge, Icon, Flashbar, TextContent, CopyToClipboard } from '@cloudscape-design/components';
import { ItemContext } from '../context/itemContext';
import { SelectedItemContextType, SelectedItem } from '../@types/item';
import { UserContext } from '../context/userContext';
import { UserInfoContextType, UserInfo } from '../@types/user';
import { fetchAuthSession } from 'aws-amplify/auth';
import ItemDetailQualificationChecklist from './components/ItemDetailQualificationChecklist';
import ItemDetailBusinesshecklist from './components/itemDetailBusinessChecklist';
import ItemDetailSolutionChecklist from './components/itemDetailSolutionsChecklist';
import ItemDetailWinLoss from './components/ItemDetailWinLoss';


export default function ItemDetail(dataTable: any) {

  const LOCALE = 'en';
  const apiUrl = 'https://h85kpb567c.execute-api.eu-west-3.amazonaws.com/prod';
  const path = '/getItemDetails';
  const pathGetEmployeeByLogin = '/papiEmployeeByLogin';
  let ItemData;

  //Context Information 
  const { user, updateUser } = React.useContext(UserContext) as UserInfoContextType;
  const { item, updateItem } = React.useContext(ItemContext) as SelectedItemContextType;
  const [tableItems, setTableItems] = useState<any[]>([]);
  const [itemDataDetails, setItemData] = useState<any[]>([]);

  const [salesforceOppyURL, setSalesforceOppyURL] = useState("https://aws-crm.lightning.force.com/lightning/r/Opportunity/[Opportunity ID]");
  const [companyName, setCompanyName] = useState("");
  const [companyIndustry, setCompanyIndustry] = useState("");
  const [regionCountry, setRegionCountry] = useState("");
  const [preQualiChecklist, setPreQualiChecklist] = useState("Incomplete");
  const [postQualificationBusiness, setPostQualificationBusiness] = useState("Incomplete");
  const [postQualificationSolution, setPostQualificationSolution] = useState("Incomplete");
  const [winLossAnalysis, setWinLossAnalysis] = useState("Incomplete");
  const [preQualiChecklistScore, setPreQualiChecklistScore] = useState(0);
  const [qualiChecklistRAG, setQualiChecklistRAG] = useState<any>("red");
  const [postQualificationBusinessScore, setPostQualificationBusinessScore] = useState(0);
  const [postQualificationBusinessRAG, setPostQualificationBusinessRAG] = useState<any>("incomplete");
  const [postQualificationSolutionScore, setPostQualificationSolutionScore] = useState(0);
  const [postQualificationSolutionRAG, setPostQualificationSolutionRAG] = useState<any>("incomplete");
  const [awsAccountExec, setAwsAccountExec] = useState("");
  const [connectSpecialist, setConnectSpecialist] = useState('');
  const [connectSpecialistSA, setConnectSpecialistSA] = useState('');
  const [qualiChecklistRAGVariant, setQualiChecklistRAGVariant] = useState<any>('error');
  const [postQualificationBusinessRAGVariant, setPostQualificationBusinessRAGVariant] = useState<any>('info');
  const [postQualificationSolutionRAGVariant, setPostQualificationSolutionRAGVariant] = useState<any>('info');
  const [qualificationRecommendation, setQualificationRecommendation] = useState('RECOMMENDED ACTION: NO BID OR ENGAGE PARTNER');
  const [businessRecommendation, setBusinessRecommendation] = useState('No Bid!')
  const [solutionRecommendation, setSolutionRecommendation] = useState('No Bid!')
  const [lastEditBy, setLastEditBy] = useState('');
  const [lastEditDate, setLastEditDate] = useState('');
  const [visible, setVisible] = React.useState(true);

  //Qualification override data
  interface qualificationStateInterface {
    label?: string;
    value?: string;
    icon?: string;
  }
  const [qualificationState, setQualificationState] = useState<qualificationStateInterface>({ label: "Not-set", value: "Not-set" });

  // * PAPI Data Plane
  const [connectSpecialistPAPI, setConnectSpecialistPAPI] = useState('');
  const [connectSpecialistPAPIbusTitle, setConnectSpecialistPAPIbusTitle] = useState('');
  const [connectSpecialistSAPAPI, setConnectSpecialistSAPAPI] = useState('');
  const [connectSpecialistSAPAPIbusTitle, setConnectSpecialistSAPAPIbusTitle] = useState('');
  const [awsAccountExecPAPI, setAwsAccountExecPAPI] = useState('');
  const [awsAccountExecPAPIbusTitle, setAwsAccountExecPAPIbusTitle] = useState('');


  const getEmployeeByLogin = async (login: string) => {
    const token = (await fetchAuthSession()).tokens?.idToken?.toString();
    const body = {
      login: login
    };
    const response = await fetch(`${apiUrl}${pathGetEmployeeByLogin}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)

    });
    const data = await response.json();
    return data
  }

  function formatDate(date: Date): string {
    const dateFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'long' });
    const timeFormatter = new Intl.DateTimeFormat('en-US', { timeStyle: 'short', hour12: false });
    return `${dateFormatter.format(date)}, ${timeFormatter.format(date)}`;
  }

  useEffect(() => {
    const getTableItems = async () => {
      const token = (await fetchAuthSession()).tokens?.idToken?.toString();
      const body = {
        id: item.id,
        user: user.username
      };
      const response = await fetch(`${apiUrl}${path}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body)

      });
      const data = await response.json();
      function getVal(id: any) {
        let obj = dataTable.data.filter((object: any) => object.id === id);
        return obj[0];
      }
      const sourceDataItem = getVal(item.id);
      setTableItems(data);
      setItemData([sourceDataItem]);
      setSalesforceOppyURL(sourceDataItem.salesforceOppyURL);
      setCompanyName(sourceDataItem.companyName);
      setCompanyIndustry(sourceDataItem.companyIndustry);
      setRegionCountry(sourceDataItem.regionCountry);
      setPostQualificationBusiness(sourceDataItem.postQualificationBusiness);
      setPreQualiChecklist(sourceDataItem.preQualiChecklist);
      setPostQualificationSolution(sourceDataItem.postQualificationSolution);
      setAwsAccountExec(sourceDataItem.awsAccountExec);
      setConnectSpecialist(sourceDataItem.connectSpecialist);
      setConnectSpecialistSA(sourceDataItem.connectSpecialistSA);
      setWinLossAnalysis(sourceDataItem.winLossAnalysis);
      setPreQualiChecklistScore(data.item[0].qualiChecklistScore);
      if (sourceDataItem.updatedBy) setLastEditBy(sourceDataItem.updatedBy);
      if(sourceDataItem.updatedAt) setLastEditDate(formatDate(sourceDataItem.updatedAt));
      if(sourceDataItem.qualificationState){
        setQualificationState(sourceDataItem.qualificationState)
      }
      try {
        setQualiChecklistRAG(data.item[0].qualiChecklistRAG);
      } catch (error) {
        console.log(`Error setting RAG: ${error}`)
      }

      if (data.item[0].qualiChecklistScore <= 0) {
        setQualiChecklistRAGVariant('error');
        setQualificationRecommendation('NO BID OR ENGAGE PARTNER');
      }
      if (data.item[0].qualiChecklistScore > 0 && data.item[0].qualiChecklistScore <= 70) {
        setQualiChecklistRAGVariant('warning');
        setQualificationRecommendation('NO BID OR PLEASE VERIFY WITH CXE SPECIALIST SELLER');
      }
      if (data.item[0].qualiChecklistScore > 70) {
        setQualiChecklistRAGVariant('success');
        setQualificationRecommendation('PLEASE PROCEED TO BUSINESS VALIDATION')
      }
      if (sourceDataItem.postQualificationBusiness === 'Completed') {
        setPostQualificationBusinessScore(data.item[0].businessChecklistScore);
        setPostQualificationBusinessRAG(data.item[0].businessChecklistRAG);
        if (data.item[0].businessChecklistScore <= 0) {
          setPostQualificationBusinessRAGVariant('error');
          setBusinessRecommendation('No Bid!')

        }
        if (data.item[0].businessChecklistScore > 0 && data.item[0].businessChecklistScore <= 80) {
          setPostQualificationBusinessRAGVariant('warning');
          setBusinessRecommendation('MORE INFO/ DEEPER ENGAGEMENT NEEDED WITH CUSTOMER TO ADDRESSS THE RED FLAGS')
        }
        if (data.item[0].businessChecklistScore > 80) {
          setPostQualificationBusinessRAGVariant('success');
          setBusinessRecommendation('OPPORTUNITY IS A GOOD BUSINESS FIT. PLEASE PROCEED TO SOLUTION ASSESSMENT')
        }
      }

      if (sourceDataItem.postQualificationSolution === 'Completed') {
        setPostQualificationSolutionScore(data.item[0].solutionsChecklistScore);
        setPostQualificationSolutionRAG(data.item[0].solutionshecklistRAG);

        if (data.item[0].solutionsChecklistScore <= 0) {
          setPostQualificationSolutionRAGVariant('error');
          setSolutionRecommendation('No Bid!')

        }
        if (data.item[0].solutionsChecklistScore > 0 && data.item[0].solutionsChecklistScore <= 25) {
          setPostQualificationSolutionRAGVariant('warning');
          setSolutionRecommendation('MORE INFO/ DEEPER ENGAGEMENT NEEDED WITH CUSTOMER TO ADDRESSS THE RED FLAGS')
        }
        if (data.item[0].solutionsChecklistScore > 25) {
          setPostQualificationSolutionRAGVariant('success');
          setSolutionRecommendation('OPPORTUNITY IS A GOOD SOLUTION  FIT')
        }
      }

      return { data, sourceDataItem };
    }

    getTableItems();


  }, [item.visible, item.id, ItemData])

  useEffect(() => {
    const fetchDetails = async () => {
      const employeeDetails = await getEmployeeByLogin(connectSpecialist);
      console.log(`Employee Details: ${JSON.stringify(employeeDetails)}`);
      setConnectSpecialistPAPI(`${employeeDetails.firstName} ${employeeDetails.lastName}`)
      setConnectSpecialistPAPIbusTitle(employeeDetails.businessTitle)
    }
    if (connectSpecialist !== '') {
      console.log(`Try to find Employee details by login`);
      fetchDetails();
    } else {
      console.log(`No Connect Specialist set`);
      setConnectSpecialistPAPI(connectSpecialist);
    }

  }, [connectSpecialist])

  useEffect(() => {
    const fetchDetails = async () => {
      const employeeDetails = await getEmployeeByLogin(connectSpecialistSA);
      console.log(`Employee Details: ${JSON.stringify(employeeDetails)}`);
      setConnectSpecialistSAPAPI(`${employeeDetails.firstName} ${employeeDetails.lastName}`)
      setConnectSpecialistSAPAPIbusTitle(employeeDetails.businessTitle)
    }
    if (connectSpecialist !== '') {
      console.log(`Try to find Employee details by login`);
      fetchDetails();
    } else {
      console.log(`No Connect Specialist set`);
      setConnectSpecialistSAPAPI(connectSpecialist);
    }
  }, [connectSpecialistSA])

  useEffect(() => {
    const fetchDetails = async () => {
      const employeeDetails = await getEmployeeByLogin(awsAccountExec);
      console.log(`Employee Details: ${JSON.stringify(employeeDetails)}`);
      setAwsAccountExecPAPI(`${employeeDetails.firstName} ${employeeDetails.lastName}`)
      setAwsAccountExecPAPIbusTitle(employeeDetails.businessTitle)
    }
    if (connectSpecialist !== '') {
      console.log(`Try to find Employee details by login`);
      fetchDetails();
    } else {
      console.log(`No Connect Specialist set`);
      setAwsAccountExecPAPI(awsAccountExec);
    }
  }, [awsAccountExec])

  const content = (
    <>
      <Modal
        onDismiss={() => {
          setVisible(false)
          updateItem({ id: item.id, owner: item.owner, visible: false, soutionsFitVisible: false, businessFitVisible: false })
        }
        }
        visible={visible}
        size='max'
        header={item.id}
      >
        <Container
          header={
            <Header
              variant="awsui-h1-sticky"
            >
              <Link external href={`${salesforceOppyURL}/view`}>Link to {companyName} Salesforce Opportunity</Link>
              <CopyToClipboard copyButtonText='Copy' variant='icon' copyErrorText='Error copy to clipboard' copySuccessText='RFx Record link copied' textToCopy={`${window.location.origin}/AllItems/${item.id}/view`} />
              <br></br>
                
             
              <TextContent><small>Last updated by {lastEditBy} at {lastEditDate} </small></TextContent>
            </Header>
          }
        >
          <form onSubmit={e => e.preventDefault()}>
            <Form>
              <SpaceBetween size="s" direction='vertical'>
                <ColumnLayout columns={4} variant="text-grid">
                  <SpaceBetween size="l">
                    <div>
                      <Box variant="awsui-key-label">Customer Name</Box>
                      <div>{companyName}</div>
                    </div>
                    <div>
                      <Box variant="awsui-key-label">Industry</Box>
                      <div>{companyIndustry}</div>
                    </div>
                    <div>
                      <Box variant="awsui-key-label">Region</Box>
                      <div>{regionCountry}</div>
                    </div>
                  </SpaceBetween>

                  <SpaceBetween size="l">
                    <div>
                      <Box variant="awsui-key-label">Pre-Qualification Checklist</Box>
                      <div><StatusIndicator type={preQualiChecklist === 'Completed' ? 'success' : 'info'}>{preQualiChecklist}</StatusIndicator></div>
                    </div>
                    <div>
                      <Box variant="awsui-key-label">Post-Qualification Business fit</Box>
                      <div><StatusIndicator type={postQualificationBusiness === 'Completed' ? 'success' : 'info'}>{postQualificationBusiness}</StatusIndicator></div>
                    </div>
                    <div>
                      <Box variant="awsui-key-label">Post-Qualification Solutions fit</Box>
                      <div><StatusIndicator type={postQualificationSolution === 'Completed' ? 'success' : 'info'}>{postQualificationSolution}</StatusIndicator></div>
                    </div>
                  </SpaceBetween>

                  <SpaceBetween size="l">
                    <div>
                      <Box variant="awsui-key-label">Pre-Qualification RAG Status</Box>
                      <StatusIndicator
                        type={qualiChecklistRAGVariant}>{qualiChecklistRAG.charAt(0).toUpperCase() + qualiChecklistRAG.slice(1)}</StatusIndicator>
                    </div>
                    <div>
                      <Box variant="awsui-key-label">Post-Qualification Business RAG Status</Box>
                      <StatusIndicator
                        type={postQualificationBusinessRAGVariant}>{postQualificationBusinessRAG.charAt(0).toUpperCase() + postQualificationBusinessRAG.slice(1)}</StatusIndicator>
                    </div>
                    <div>
                      <Box variant="awsui-key-label">Post-Qualification Solutions RAG Status</Box>
                      <StatusIndicator
                        type={postQualificationSolutionRAGVariant}>{postQualificationSolutionRAG.charAt(0).toUpperCase() + postQualificationSolutionRAG.slice(1)}</StatusIndicator>
                    </div>
                  </SpaceBetween>

                  <SpaceBetween size="l">
                    <div>
                      <Box variant="awsui-key-label">AWS Account Executive</Box>
                      <Link
                        variant='info'
                        href={`https://phonetool.amazon.com/users/${awsAccountExec}`}>{awsAccountExecPAPI}</Link><br />
                        <small style={{ color: 'gray' }}>{awsAccountExecPAPIbusTitle}</small>
                    </div>
                    <div>
                      <Box variant="awsui-key-label">AWS Connect Specialist</Box>
                      <Link
                        variant='info'
                        href={`https://phonetool.amazon.com/users/${connectSpecialist}`}>{connectSpecialistPAPI}</Link><br />
                      <small style={{ color: 'gray' }}>{connectSpecialistPAPIbusTitle}</small>

                    </div>
                    <div>
                      <Box variant="awsui-key-label">AWS Connect Specialist SA</Box>
                      <Link
                        variant='info'
                        href={`https://phonetool.amazon.com/users/${connectSpecialistSA}`}>{connectSpecialistSAPAPI}</Link><br />
                        <small style={{ color: 'gray' }}>{connectSpecialistSAPAPIbusTitle}</small>
                    </div>

                  </SpaceBetween>
                </ColumnLayout>
                <ColumnLayout columns={4} variant='text-grid'>
                  <SpaceBetween size="l">
                    <div>
                      <Box variant="awsui-key-label">Qualification Recommendation</Box>
                      {qualificationRecommendation}


                    </div>
                  </SpaceBetween>
                  <SpaceBetween size="l">
                    <div>
                      <Box variant="awsui-key-label">Business Recommendation</Box>
                      <div>{businessRecommendation}</div>
                    </div>
                  </SpaceBetween>
                  <SpaceBetween size="l">
                    <div>
                      <Box variant="awsui-key-label">Solutions Recommendation</Box>
                      <div>{solutionRecommendation}</div>
                    </div>
                  </SpaceBetween>
                  <SpaceBetween size="l">
                    <div>
                    <Box variant="awsui-key-label" >Qualification override <Popover dismissButton={true}
                          header="Bid / no-Bid decision" content={<small>Indicate if you decide to proceed participating to this tender.</small>}><small>Info</small></Popover></Box>
                      <div>{qualificationState.value}</div>
                    </div>
                  </SpaceBetween>
                </ColumnLayout>

                <ItemDetailQualificationChecklist data={tableItems} sourceData={itemDataDetails}></ItemDetailQualificationChecklist>
                {postQualificationBusiness === 'Completed' && (
                  <ItemDetailBusinesshecklist data={tableItems} sourceData={itemDataDetails}></ItemDetailBusinesshecklist>
                )}
                {postQualificationSolution === 'Completed' && (
                  <ItemDetailSolutionChecklist data={tableItems} sourceData={itemDataDetails}></ItemDetailSolutionChecklist>
                )}
                <ItemDetailWinLoss data={tableItems} sourceData={itemDataDetails}></ItemDetailWinLoss>

              </SpaceBetween>
            </Form>
          </form>

        </Container>

      </Modal>
    </>
  )

  return content
}